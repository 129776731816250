import React from 'react';
import Fade from 'react-reveal/Fade';

const Services = () => {
    return (
        <section id='services' className='services section'>
            <Fade top appear>
                <header className='container section-title'>
                    <h2>Our Services</h2>
                    <p>
                        At TheDevTribe, we offer a range of services that harness the power of creativity and technology to deliver exceptional digital solutions.
                    </p>
                    <p>
                        Our services include:
                    </p>
                </header>
            </Fade>

            <div className='container'>
                <div className='row g-5'>
                    <Fade left appear>
                        <article className='col-lg-6'>
                            <div className='service-item item-cyan position-relative'>
                                <i className="bi bi-browser-chrome icon" aria-hidden="true"></i>
                                <div>
                                    <h3>Web Development</h3>
                                    <p>
                                        Creating robust, scalable, and visually appealing websites that engage users and enhance online presence.
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article className='col-lg-6'>
                            <div className='service-item item-orange position-relative'>
                                <i className="bi bi-phone icon" aria-hidden="true"></i>
                                <div>
                                    <h3>Mobile App Development</h3>
                                    <p>
                                        Designing intuitive and feature-rich mobile applications for Android platforms, ensuring seamless functionality and delightful user experiences.
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article className='col-lg-6'>
                            <div className='service-item item-teal position-relative'>
                                <i className="bi bi-patch-check icon" aria-hidden="true"></i>
                                <div>
                                    <h3>UI/UX Design</h3>
                                    <p>
                                        Crafting seamless and intuitive user experiences through innovative design and user research.
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article className='col-lg-6'>
                            <div className='service-item item-red position-relative'>
                                <i className="bi bi-badge-3d icon" aria-hidden="true"></i>
                                <div>
                                    <h3>3D Art</h3>
                                    <p>
                                        Bringing stories to life with stunning 3D models, and visual effects for a variety of media.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </Fade>

                    <Fade right appear>
                        <article className='col-lg-6'>
                            <div className='service-item item-indigo position-relative'>
                                <i className="bi bi-hand-index-thumb icon" aria-hidden="true"></i>
                                <div>
                                    <h3>Interactive Features</h3>
                                    <p>
                                        Adding dynamic features and animations to engage users and enhance interactivity across platforms.
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article className='col-lg-6'>
                            <div className='service-item item-pink position-relative'>
                                <i className="bi bi-universal-access-circle icon" aria-hidden="true"></i>
                                <div>
                                    <h3>Accessibility</h3>
                                    <p>
                                        Ensuring your digital products are usable and inclusive for all users, including those with disabilities.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </Fade>
                </div>
            </div>
        </section>
    );
}

export default Services;
