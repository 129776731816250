import React from 'react';
import './App.css';
import Hero from './Components/Hero';
import Features from './Components/Features';
import About from './Components/About';
import FeaturesDetail from './Components/FeaturesDetail';
import Services from './Components/Services';
import ProjectsHome from './Components/ProjectsHome';
import Team from './Components/Team';
import Faqs from './Components/Faqs';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import FooterAnimator from './Components/FooterAnimator';


const MainRoute = () => {
  return (
    <div>
          <Hero />
      <section id="features"><Features /></section>
      <section id="about"><About /></section>
      <section id="features-detail"><FeaturesDetail /></section>
      <section id="services"><Services /></section>
      <section id="projectshome"><ProjectsHome /></section>
      {/* <section id="team"><Team /></section> */}
      <section id="faqs"><Faqs /></section>
      <section id="contact"><Contact /></section>
      <FooterAnimator />
      <Footer />
    </div>
  );
}

export default MainRoute;
