import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import logo from "../img/logo-dev.png";
import { FaArrowUp } from 'react-icons/fa';

const Footer = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.replace('#', ''));
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 0);
            }
        }
    }, [location]);

    const handleScroll = (sectionId) => {
        navigate('/'); // Navigate to the home page if needed
        setTimeout(() => {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100); // Adjust timeout if needed
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <footer id="footer" className="footer position-relative">
            <Fade left appear>
                <div className="container footer-top">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <a href="/" className="logo d-flex align-items-center" aria-label="Homepage">
                                <img src={logo} alt="DevTribe Logo" style={{ marginRight: "20px" }} />
                            </a>
                            <div className="footer-contact pt-3">
                                <p className="mt-0"><strong>Phone:</strong> <span>+92 3015513637</span></p>
                                <a href='mailto:hr@thedevtribe.com' aria-label="Email DevTribe"><strong>Email:</strong> <span>hr@thedevtribe.com</span></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><a href="#home" onClick={() => handleScroll('home')} aria-label="Home">Home</a></li>
                                <li><a href="#about" onClick={() => handleScroll('about')} aria-label="About Us">About</a></li>
                                <li><a href="#services" onClick={() => handleScroll('services')} aria-label="Our Services">Services</a></li>
                                <li><a href="/projects" aria-label="Projects">Projects</a></li>
                                <li><a href="#contact" onClick={() => handleScroll('contact')} aria-label="Contact Us">Contact</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Services</h4>
                            <ul>
                                <li><a href="#services" onClick={() => handleScroll('services')} aria-label="Web Development">Web Development</a></li>
                                <li><a href="#services" onClick={() => handleScroll('services')} aria-label="Mobile App Development">Mobile App Development</a></li>
                                <li><a href="#services" onClick={() => handleScroll('services')} aria-label="UI/UX Design">UI/UX</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-4 col-md-12 d-flex justify-content-between align-items-center">
                            <div className="social-links d-flex mt-4">
                                <a href="https://www.instagram.com/thedevtribes?igsh=MWg3MXNiOTUxOXZwdA==" aria-label="Instagram"><i className="bi bi-instagram"></i></a>
                                <a href="https://www.linkedin.com/company/thedevtribe/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BwNpYLYJ6RZeO2xIGxQtXdw%3D%3D" aria-label="LinkedIn"><i className="bi bi-linkedin"></i></a>
                            </div>
                            <button className="scroll-to-top" onClick={scrollToTop} aria-label="Scroll to top">
                                <FaArrowUp size={20} />
                            </button>
                        </div>
                    </div>
                </div>
            </Fade>

            <Fade right appear>
                <div className="container copyright text-center mt-4">
                    <p>© <span>Copyright</span> <strong className="px-1 sitename">TheDevTribe</strong><span>All Rights Reserved</span></p>
                    <div className="credits">
                        Designed by <a href="https://thedevtribe.com/" aria-label="DevTribe">TheDevTribe</a>
                    </div>
                </div>
            </Fade>
        </footer>
    );
}

export default Footer;
